import React, { useEffect, useState } from "react";
import LineupCard from "../../components/cards/lineupcards/LineupCard";
import "./lineup.css";
import LineupHeader from "../../components/assets/DD-24-tix-lineup/Lineup/SVG/lineup-2x.svg";
import FriBtn from "../../components/assets/DD-24-tix-lineup/Lineup/PNG/Friday@2x.png";
import Satbtn from "../../components/assets/DD-24-tix-lineup/Lineup/PNG/SATRUDAY@2x.png";
import LineupSchedule from "../../components/assets/DD-24-tix-lineup/Lineup/PNG/Schedule@2x.png";
import moonLogo from "../../components/assets/DD-24-tix-lineup/Lineup/SVG/moon-2x.svg";
import discoLogo from "../../components/assets/DD-24-tix-lineup/Lineup/SVG/disco-2x.svg";
import initializeFacebookPixel from "../../utils/metapixel"; // Import the metapixel.js file
import ddBorder from "../../components/assets/DreamyDraw-24-Homepage-Assets/border-2x.svg";
import FridayLineupCard from "../../components/cards/lineupcards/FridayLineupCard";
import SatLineupCard from "../../components/cards/lineupcards/SatLineupCard";
import FriLineupImg from "../../images/DreamyDraw-IG-SetTimes-1-Square-Friday.png";
import SatLineupImg from "../../images/DreamyDraw-IG-SetTimes-1-Square-Saturday.png";
import { useLocation } from "react-router-dom"; // ADD USELOCATION HOOK

const Lineup = () => {
  const location = useLocation(); // ADD USELOCATION HOOK
  const [activeClass, setActiveClass] = useState(
    new URLSearchParams(location.search).get("activeClass") || "ThirdLineup" // INITIALIZE STATE FROM URL
  );

  useEffect(() => {
    initializeFacebookPixel();
    window.fbq("track", "HomePage");
  }, []);

  const handleButtonClick = (newActiveClass) => { // ADD HANDLEBUTTONCLICK FUNCTION
    setActiveClass(newActiveClass); // UPDATE STATE
    const url = new URL(window.location);
    url.searchParams.set("activeClass", newActiveClass); // UPDATE URL
    window.history.pushState({}, '', url); // PUSH URL TO HISTORY
  };

  return (
    <>
      <div className="lineup-header-container">
        <img
          className="lineup-backimg"
          src={LineupHeader}
          alt="lineup header background"
        />
        <img
          src={ddBorder}
          alt="dd-border"
          className="dd-border-image"
          style={{ width: "100%" }}
        />
      </div>

      {/* lineup buttons  */}
      <div className="lineup-element-cover" style={{ position: "relative" }}>
        <img
          src={moonLogo}
          alt="Moon Logo design"
          style={{ position: "absolute", top: "10%", left: 0, width: "13%" }}
        />
        <img
          src={discoLogo}
          alt="Disco Logo design"
          style={{ position: "absolute", bottom: "10%", right: 0, width: "13%" }}
        />
        <div className="lineup-filter-btns">
          <img
            src={FriBtn}
            alt="A-Z Artist"
            className="lineup-button"
            onClick={() => handleButtonClick("FirstLineup")}
          />
          <img
            src={Satbtn}
            alt="A-Z Artist"
            className="lineup-button"
            onClick={() => handleButtonClick("SecondLineup")}
          />
          <img
            src={LineupSchedule}
            alt="A-Z Artist"
            className="lineup-button"
            onClick={() => handleButtonClick("ThirdLineup")}
          />
        </div>
        {activeClass === "FirstLineup" && (
          <div className="lineup-card-display">
            <FridayLineupCard />
          </div>
        )}

        {activeClass === "SecondLineup" && (
          <div className="lineup-card-display">
            <SatLineupCard />
          </div>
        )}

        {activeClass === "ThirdLineup" && (
          <div className="schedule-lineup">
            <img src={FriLineupImg} alt="FriLineupImg" />
            <img src={SatLineupImg} alt="SatLineupImg" />
          </div>
        )}
      </div>
    </>
  );
};

export default Lineup;
