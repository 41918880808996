import React from "react";
import "./faqs.css";
import { IconContext } from "react-icons";
import GeneralInfo from "./GeneralInfo";
import GettingHere from "./GettingHere";
import TicketQandA from "./TicketQandA";
import Venue from "./Venue";
import WhatCanIBring from "./WhatCanIBring";
import WhatCantIBring from "./WhatCantIBring";
import GetInvolved from "./GetInvolved";
// import Hotels from "./Hotels";
import ddmap from "../../images/DreamyDraw-Map-2024.png";

const Faqs = () => {
  return (
    // IconContext lets you add style to react-icons
    <IconContext.Provider value={{ color: "#000", size: "25px" }}>
      <div className="faqs-section">
        <img className="dd-map" src={ddmap} alt="Site map" /> 
        <div className="faqs-header">
          <h1>Things to Know</h1>
        </div>
        <GeneralInfo />
        <GetInvolved />
        <WhatCanIBring />
        <WhatCantIBring />
        <GettingHere />
        <TicketQandA />
        <Venue />
        {/* <Hotels /> */}
      </div>
    </IconContext.Provider>
  );
};

export default Faqs;
